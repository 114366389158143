import React, {
  useState,
  useRef,
  useEffect,
  MutableRefObject,
  CSSProperties,
  SyntheticEvent,
} from "react";
import moment from "moment";
import TimeComboBox from "./../../apps/common/TimeComboBox";
import { formatDate } from "./../../utils/dateUtils";
import { Button, Form } from "semantic-ui-react";

import DateTextBox from "react-lib/apps/common/DateTextBox";
import { useIntl } from "react-intl";

type PickerColumnProps = {
  id?: any;
  onDataChanged?: (date: string, humanAction: boolean) => void;
  value: string;
  type?: "datetime" | "date" | "time"; // dd/mm/yyyy HH:mm (BE) , dd/mm/yyyy (BE), HH:mm
  clearable?: boolean;
  position?: "top left" | "bottom right";
  changeOnBlur?: boolean;
  closeOnChange?: boolean;
  textStyle?: any;
};

const PopupStyle = {
  position: "absolute",
  opacity: 0,
  backgroundColor: "#ffffff",
  border: "1px solid #759dc0",
  padding: "6px 8px",
  borderRadius: 4,
  boxShadow: "0 1px 3px rgb(0 0 0 / 25%)",
  fontSize: "1em",
  color: "#000000",
  // marginLeft: "-220px",
  // marginTop: "-140px",
  minWidth: "200px",
  zIndex: 2,
} as CSSProperties;

const ColumnStyle = {
  background: "rgb(255, 255, 204)",
  marginTop: -7,
  marginLeft: -4,
  width: "calc(100% + 10px)",
  height: "calc(100% + 14px)",
  padding: "7px 7px",
} as CSSProperties;

const PickerColumn: React.FC<PickerColumnProps> = React.memo<PickerColumnProps>(
  (props) => {
    const intl = useIntl();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [date, setDate] = useState<string>("");
    const [time, setTime] = useState<string>("");

    const isFocus = useRef(false) as MutableRefObject<boolean>;
    const columnRef = useRef() as MutableRefObject<HTMLDivElement>;
    const popupRef = useRef() as MutableRefObject<HTMLDivElement>;
    const marginTopRef = useRef<number>(0);
    const tBodyRef = useRef() as MutableRefObject<HTMLDivElement>;

    useEffect(() => {
      tBodyRef.current = columnRef.current.closest(
        ".rt-tbody"
      ) as HTMLDivElement;

      // console.log("useEffect Check !!! handleMouseClick: ", handleMouseClick);
      if (isOpen) {
        console.log(
          " set onClick to handleMouseClick from date:, time:",
          time,
          date
        );
        document.onclick = handleMouseClick;
        tBodyRef.current.addEventListener("scroll", handleOnScroll);
      } else {
        // Issue 58969
        // document.onclick = null;
        tBodyRef.current.removeEventListener("scroll", handleOnScroll);
      }
    }, [isOpen, time, date]);

    useEffect(() => {
      // console.log("useEffect: ");
      if (props.type !== "date") {
        return;
      }

      // console.log(
      //   "useEffect date: ",
      //   date,
      //   "props.closeOnChange: ",
      //   props.closeOnChange
      // );
      if (date !== props.value) {
        if (props.closeOnChange) {
          console.log(" date != props.value");
          handleOnBlur();
          handleClose();
        } else if (!props.changeOnBlur) {
          console.log(" date == props.value");
          handleOnBlur(false);
        }
      }

      setTimeout(() => {
        isFocus.current = false;
      }, 200);
    }, [date]);

    useEffect(() => {
      // console.log("useEffect: ");

      if (props.type !== "time") {
        return;
      }

      // console.log(
      //   "useEffect time: ",
      //   date,
      //   "props.closeOnChange: ",
      //   props.closeOnChange
      // );
      if (time !== props.value) {
        if (props.closeOnChange) {
          console.log(" time != props.value");
          handleOnBlur();
          handleClose();
        } else if (!props.changeOnBlur) {
          console.log(" time == props.value");
          handleOnBlur(false);
        }
      }
      setTimeout(() => {
        isFocus.current = false;
      }, 200);
    }, [time]);

    useEffect(() => {
      if (isOpen) {
        if (props.position === "top left") {
          marginTopRef.current = popupRef.current.offsetHeight + 12;

          popupRef.current.style.marginLeft = `-${
            popupRef.current.offsetWidth - columnRef.current.offsetWidth
          }px`;
          popupRef.current.style.marginTop = `-${marginTopRef.current}px`;
        } else if (props.position === "bottom right") {
          marginTopRef.current = columnRef.current.offsetHeight;

          popupRef.current.style.marginTop = `${marginTopRef.current}px`;
        }

        handleOnScroll({ target: tBodyRef.current });

        popupRef.current.style.opacity = "1";
      }
    }, [isOpen]);

    const handleOnScroll: (e: any) => void = (e) => {
      console.log("onscroll");
      if (popupRef.current?.style) {
        const top = e.target.scrollTop;
        if (props.position === "top left") {
          popupRef.current.style.marginTop = `-${marginTopRef.current + top}px`;
        } else if (props.position === "bottom right") {
          popupRef.current.style.marginTop = `${marginTopRef.current - top}px`;
        }
      }
    };

    const handlePreventDefault = (e?: SyntheticEvent) => {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }
    };

    /**
     *
     * @param e
     */

    const handleMouseClick = (e: any) => {
      handlePreventDefault(e);
      // console.log(
      //   "handleMouseClick isOpen: ",
      //   isOpen,
      //   " isFocus.current: ",
      //   isFocus.current
      // );

      if (isFocus?.current !== null && isOpen && !isFocus.current) {
        if (!props.changeOnBlur) {
          console.log("call handleClose when props.changeOnBlur is false");
          handleClose();
        } else {
          console.log("call handleOnBlur when props.changeOnBlur is true");
          handleOnBlur();
        }
      }
    };

    const handleOnBlur = (isClose: boolean = true) => {
      console.log("handleOnBlur close", isClose, isOpen, isFocus.current);
      let dateTime = "";
      const formatTime = `${time}:${moment().format("ss")}`;

      console.log("handleOnBlur date: ", date);
      console.log("handleOnBlur time: ", time);
      if (date && time) {
        dateTime = `${date}T${formatTime}`;
      } else if (date) {
        dateTime = `${date}T${moment().format("HH:mm:ss")}`;
      } else if (time) {
        dateTime = `${formatDate(moment())}T${formatTime}`;
      }

      dateTime = dateTime.replace(/(\d{2})\/(\d{2})\/(\d{4})/g, "$3-$2-$1");

      dateTime =
        props.type === "date" ? date : props.type === "time" ? time : dateTime;

      console.log("dateTime: ", dateTime);
      props.onDataChanged?.(dateTime, isOpen);

      if (isClose) {
        handleClose();
      }
    };

    const handleClose = () => {
      // console.log("handleClose: ");
      setIsOpen(false);
      // Issue 58969
      // document.onclick = null;
      tBodyRef.current.removeEventListener("scroll", handleOnScroll);
    };

    const handleClick = (e: any) => {
      // console.log("handleClick isOpen: ", isOpen, "e.target: ", e.target);
      handlePreventDefault(e);

      if (!isOpen) {
        isFocus.current = true;
        const split = props.value?.split(" ") || [""];

        // console.log("split", split)
        if (props.type === "time") {
          split[1] = split[0];
        }

        setTimeout(() => {
          isFocus.current = false;
          setTime(split[1] || "");
        }, 100);

        if (["datetime", "date"].includes(props.type || "")) {
          setDate(split[0] || "");
        }
        // console.log("setIsOpen = true");
        setIsOpen(true);
      }
    };

    const handleClear = () => {
      setDate("");
      setTime("");
    };

    // console.log("PickerColumn: props: ", props, date, time);
    return (
      <>
        {isOpen && (
          <div
            id={props.id}
            ref={popupRef}
            style={PopupStyle}
            onMouseOver={(e) => {
              // console.log(
              //   "onMouseOver !!! isFocus: ",
              //   isFocus,
              //   ", e.target: ",
              //   e.target
              // );
              isFocus.current = true;
            }}
            onClick={() => {
              // console.log("onClick !!! ");
              isFocus.current = true;
            }}
            onMouseLeave={(e) => {
              // console.log(
              //   "onMouseLeave !!! isFocus: ",
              //   isFocus,
              //   ", e.target: ",
              //   e.target
              // );
              isFocus.current = false;
            }}
            onMouseOut={(e) => {
              // console.log(
              //   "onMouseOut !!! isFocus: ",
              //   isFocus,
              //   ", e.target: ",
              //   e.target
              // );
              isFocus.current = false;
            }}
          >
            <Form>
              {["datetime", "date"].includes(props.type || "") && (
                <Form.Group
                  inline
                  style={{ margin: props.type === "date" ? 0 : "" }}
                >
                  <Form.Field width={props.clearable ? 13 : 16}>
                    <label style={{ width: "32px", minWidth: "max-content" }}>{intl.formatMessage({ id: "วันที่" })}</label>
                    <DateTextBox
                      id={props.id ? `PickerColumn-DateTextBox-${props.id}` : `PickerColumn-DateTextBox`}
                      value={date}
                      onChange={setDate}
                      style={{ minWidth: "190px" }}
                    />
                  </Form.Field>
                  {props.clearable && props.type === "date" && (
                    <ClearButton onClear={handleClear} />
                  )}
                </Form.Group>
              )}
              {["datetime", "time"].includes(props.type || "") && (
                <Form.Group
                  inline
                  style={{ margin: props.type === "time" ? 0 : "" }}
                >
                  <Form.Field width={props.clearable ? 13 : 16}>
                    <label>{intl.formatMessage({ id: "เวลา" })}</label>
                    <TimeComboBox
                      id={props.id ? `PickerColumn-TimeComboBox-${props.id}` : `PickerColumn-TimeComboBox`}
                      defaultValue={time}
                      onTextChange={setTime}
                      style={{ width: "100%" }}
                    />
                  </Form.Field>
                  {props.clearable && <ClearButton onClear={handleClear} />}
                </Form.Group>
              )}
            </Form>
          </div>
        )}
        <div
          id={props.id}
          ref={columnRef}
          style={{
            ...ColumnStyle,
            ...(props.textStyle ? props.textStyle : {}),
          }}
          onClick={handleClick}
        >
          {props.value}
        </div>
      </>
    );
  }
);

const ClearButton = (props: any) => {
  return (
    <Form.Field width={3}>
      <Button id={props.id} color="red" icon="trash" onClick={props.onClear}></Button>
    </Form.Field>
  );
};

PickerColumn.defaultProps = {
  value: "",
  type: "datetime",
  clearable: true,
  position: "top left",
  changeOnBlur: true,
  closeOnChange: false,
} as PickerColumnProps;

export default PickerColumn;
