import React, {
  useState,
  useEffect,
  CSSProperties,
  MutableRefObject,
  useRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  SyntheticEvent,
  MouseEvent,
  KeyboardEvent,
} from "react";
import moment from "moment";
import { SemanticICONS, DropdownProps, Checkbox, Input } from "semantic-ui-react";

// CSS
import "../../css/DropdownOptions.scss";
import { useIntl } from "react-intl";

// Types
type DropdownOptionsProps = {
  id?: string;
  value?: number | string | number[] | string[] | (string | number)[];
  style?: CSSProperties;
  inputStyle?: CSSProperties;
  iconStyle?: CSSProperties;
  icon?: SemanticICONS;
  disabled?: boolean;
  placeholder?: string;
  options?: { key: any; text: string; value: any; subOptions?: any[] }[];
  multiple?: boolean;
  search?: boolean;
  fluid?: boolean;
  name?: string;
  showSubOptions?: boolean;
  // config
  formatTimeOnBlur?: boolean;
  checked?: boolean;
  noMinWidth?: boolean;
  searchBox?: boolean;
  inline?: boolean;
  clearable?: boolean;
  filter?: boolean;
  // callback
  // void or return
  onChange?: (event: SyntheticEvent<HTMLElement, Event>, data: any) => void;
};

const IconStyle = {
  cursor: "pointer",
  position: "absolute",
  width: "auto",
  height: "auto",
  top: "calc(50% - 25%)",
  right: "0.75em",
  zIndex: 3,
  margin: "-0.78571429em",
  padding: "0.91666667em",
  opacity: 0.8,
  transition: "opacity .1s ease",
} as CSSProperties;

const DropdownOptions = React.forwardRef<any, DropdownOptionsProps>((props, ref) => {
  const [open, setOpen] = useState<boolean>(false);
  const [value, setValue] = useState<any>();
  const [inputValue, setInputValue] = useState<any>("");
  const [options, setOptions] = useState<any[]>([]);

  const inputRef = useRef(null) as MutableRefObject<any>;
  const inputSearchRef = useRef(null) as MutableRefObject<any>;

  const isFocus = useRef(false) as MutableRefObject<boolean>;

  useImperativeHandle<any, any>(ref, () => inputRef.current);

  const allOptions = useMemo(() => {
    return props.showSubOptions
      ? props.options?.flatMap((option, index) => [
          {
            ...option,
            parent: true,
            groupId: index + 1,
            children: (option.subOptions || []).map((item) => item.value),
          },
          ...(option.subOptions || []).map((item) => ({
            ...item,
            child: true,
            groupId: index + 1,
          })),
        ])
      : props.options;
  }, [props.options, value]);

  const optionsWithoutParent = useMemo(() => {
    return allOptions?.filter((option) => !option.parent);
  }, [allOptions]);

  const filterOptions = useMemo(() => {
    return props.filter && Array.isArray(value)
      ? options.filter((option) => !value.includes(option.value))
      : options;
  }, [options, value]);

  // Callback Memo -----
  const valueText = useMemo(() => {
    return allOptions?.find((item) => item.value === value)?.text || "";
  }, [allOptions, value]);

  const isSearch = useMemo(() => {
    return props.search && !props.multiple && !props.formatTimeOnBlur;
  }, [props.search, props.multiple, props.formatTimeOnBlur]);
  // -----

  // Effect Callback -----
  const handleMouseClick = useCallback(
    (e: any) => {
      if (!isFocus.current && open) {
        if (isSearch) {
          setInputValue(valueText);
        }

        if (props.formatTimeOnBlur) {
          formatTime({ target: { value: inputRef.current.value } });
        }

        handleBlur();
      }
    },
    [open]
  );
  // -----

  // Use Effect
  useEffect(() => {
    // Event mouse click, close dropdown
    if (open) {
      document.addEventListener("click", handleMouseClick);
    } else {
      document.removeEventListener("click", handleMouseClick);
    }
    return () => {
      document.removeEventListener("click", handleMouseClick);
    };
  }, [open]);

  useEffect(() => {
    if (typeof props.value !== "undefined") {
      setValue(props.value);
    }
  }, [props.value]);

  useEffect(() => {
    if (!props.multiple) {
      setInputValue(valueText);
    } else if (props.multiple && !Array.isArray(value)) {
      setValue((props.value ? [props.value] : []).flat());
    }
  }, [props.multiple, value, valueText]);

  useEffect(() => {
    setOptions(allOptions || []);
  }, [allOptions]);

  // Callback
  const getChecked = useCallback(
    (data: any) => {
      let checked = false;

      if (data.parent) {
        if (Array.isArray(value)) {
          checked = data.children.every((id: number) => (value as any)?.includes(id));
        }
      } else if (Array.isArray(value)) {
        checked = (value as any)?.includes(data.value) || data.value === value;
      }

      return checked;
    },
    [value]
  );

  // Use Memo
  // Check ว่ามีการถูกเลือกหรือไม่
  const isSelected = useMemo(() => {
    return (
      (props.multiple && Array.isArray(value) ? !!value?.length : !!value) &&
      !props.formatTimeOnBlur
    );
  }, [value, props.multiple, props.formatTimeOnBlur]);

  const isPlaceholderValue = useMemo(() => {
    return open && props.search && !props.multiple;
  }, [open, props.search, props.multiple]);

  const placeholderValue = useMemo(() => {
    let displayText = props.placeholder;

    if (isSelected) {
      if (isPlaceholderValue) {
        displayText = valueText;
      } else {
        displayText = "";
      }
    }

    return displayText;
  }, [props.placeholder, isPlaceholderValue, isSelected]);

  // Handler
  // Format time, ใช้สำหรับ TimeComboBox
  const formatTime = (e: any) => {
    let value = inputRef?.current?.value;

    if (!value) {
      return handleChangeValue(value)(e);
    }

    const shiftZero = value.length === 1 || /^\d\D/g.test(value) ? `0${value}` : value;
    const cleanText = shiftZero.replace(/\D+/g, "").padEnd(4, "0");

    const overTime = Number(cleanText[0] + cleanText[1]) > 24 ? `0${cleanText}` : cleanText;

    const overMinute =
      Number(overTime[2] + overTime[3]) > 59
        ? `${Number(overTime[0] + overTime[1]) + 1}`.padStart(2, "0") +
          `${Number(overTime[2] + overTime[3]) - 60}`.padStart(2, "0")
        : overTime;

    const overHour = (Number(overMinute[0] + overMinute[1]) % 24).toString().padStart(2, "0");

    const time = `${overHour[0]}${overHour[1]}${overMinute[2]}${overMinute[3] || 0}`;

    handleChangeValue(`${time[0]}${time[1]}:${time[2]}${time[3]}`)(e);
  };

  const handleFocus = () => {
    if (props.disabled) {
      return;
    }

    isFocus.current = true;

    setOpen(true);

    if (isSearch) {
      setInputValue("");
    }

    setTimeout(() => {
      isFocus.current = false;
    }, 200);
  };

  const handleBlur = () => {
    setOpen(false);

    inputRef.current?.blur();

    if (!props.formatTimeOnBlur) {
      setOptions(allOptions || []);
    }
  };

  const handleChangeInput = (e: any) => {
    if (!props.disabled) {
      const value = e.target.value as string;

      setInputValue(value);

      // ค้นหารายการ
      if (props.search && !props.formatTimeOnBlur) {
        let search = (allOptions || []).filter((item) =>
          item.text?.toLowerCase().includes(value.toLowerCase())
        );

        if (props.showSubOptions) {
          const groupIds = search.map((item) => item.groupId);
          search = (allOptions || []).filter((option) => groupIds.includes(option.groupId));
        }

        setOptions(search);
      }
    }
  };

  const handleChangeValue =
    (optionValue: any, data?: any) => (e: SyntheticEvent<HTMLElement, Event>) => {
      // set value ตาม multiple หรือ 1
      let { value: id, children, parent }: any = { value: optionValue, ...(data?.item || {}) };

      let newValue: string | string[] = id;

      if (props.multiple && Array.isArray(value)) {
        if (parent && data.checked) {
          newValue = Array.from(new Set([...value, ...children]));
        } else if (parent && !data.checked) {
          newValue = value.filter((acc: any) => !children.includes(acc));
        } else if (value.includes(id)) {
          newValue = value.filter((acc) => acc !== id);
        } else {
          newValue = [...value, id];
        }
      }

      const formatValue = newValue;

      if (typeof props.value === "undefined") {
        return setValue(formatValue);
      }

      props.onChange?.(e, { value: formatValue, name: props.name });
    };

  const handlePrevent = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleClickCombobox = () => {
    // เป็น multiple และ click ภายใน combobox

    // เพื่อ ให้ focus input และ open dropdown
    if (props.multiple && props.search) {
      inputRef?.current?.focus();

      if (props.searchBox) {
        setTimeout(() => {
          inputSearchRef?.current?.inputRef?.current?.focus();
        });
      }
    } else if (!props.search) {
      handleFocus();
    }
  };

  const handleRemoveValue = (value: any, data: any) => (e: any) => {
    handleChangeValue(value, data)(e);
  };

  const handleSelected = (value: any, data: any) => (e: SyntheticEvent<HTMLElement, Event>) => {
    if (!!data?.item?.parent && !props.multiple) {
      return;
    }

    if (props.multiple && props.search) {
      handleChangeInput({ target: { value: "" } });
    }

    handleChangeValue(value, data)(e);

    // close on selected
    if (!props.multiple) {
      if (isSearch) {
        setInputValue(valueText);
      }

      handleBlur();
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    const input = (e.target as HTMLInputElement).value;

    if (
      e.key === "Backspace" &&
      !input &&
      props.multiple &&
      props.search &&
      Array.isArray(value) &&
      value[0]
    ) {
      handleChangeValue(value.slice(-1)[0])(e);
    }
  };

  return (
    <div
      className={`DropdownOptions ${props.searchBox || props.inline ? "--inline-label" : ""}`}
      style={{ ...props.style }}
    >
      <div
        id={props.id ? props.id : `DropdownOptions-Div`}
        // role="combobox"
        aria-label="options custom"
        aria-expanded={open}
        aria-controls="listbox1"
        aria-hidden="true"
        className={`ui ${open ? "active visible" : ""} search selection dropdown${
          props.multiple ? " multiple" : ""
        }${props.search ? " search" : ""}${props.fluid ? " fluid" : ""}${
          props.disabled ? " disabled" : ""
        }`}
        style={{
          width: "100%",
          minWidth: props.noMinWidth || props.fluid ? "" : "auto",
          ...(props.disabled ? { borderColor: "rgba(34,36,38,.15)", opacity: 0.5 } : {}),
          ...(props.inputStyle || {}),
        }}
        onClick={handleClickCombobox}
      >
        <LabelList
          multiple={props.multiple}
          value={value}
          optionsWithoutParent={optionsWithoutParent}
          // callback
          onPrevent={handlePrevent}
          onMouseOver={() => (isFocus.current = true)}
          onFocus={() => (isFocus.current = true)}
          onMouseLeave={() => (isFocus.current = false)}
          onClick={(e: any, data: any) => {
            isFocus.current = true;
            handleRemoveValue(data.value, data)(e);
          }}
        />
        <input
          id={props.id ? `${props.id}-Input` : `DropdownOptions-Input`}
          ref={inputRef}
          value={props.searchBox ? "" : inputValue}
          type="text"
          readOnly={!props.search}
          autoComplete="off"
          className="search"
          placeholder={placeholderValue}
          disabled={props.disabled}
          style={{
            width: isSelected ? "" : "100%",
            ...(isSelected && (props.searchBox || (props.inline && props.multiple))
              ? { position: "absolute", opacity: 0, pointerEvents: "none" }
              : {}),
          }}
          onFocus={handleFocus}
          // onBlur={handleBlur}
          onChange={handleChangeInput}
          onKeyDown={handleKeyDown}
          onMouseOver={() => (isFocus.current = props.search || false)}
          onClick={() => (isFocus.current = props.search || false)}
          onMouseLeave={() => (isFocus.current = false)}
        />
        <i
          id={props.id ? `${props.id}-i` : "DropdownOptions-i"}
          aria-hidden="true"
          className={`${
            isSelected && !props.multiple && props.clearable ? "clear" : props.icon || "dropdown"
          } icon`}
          style={{
            ...IconStyle,
            cursor: props.disabled ? "auto" : "pointer",
            opacity: props.disabled ? 0.65 : 0.45,
            ...(props.iconStyle || {}),
          }}
          onClick={(e) => {
            if (!props.disabled) {
              if (props.formatTimeOnBlur) {
                handlePrevent(e as any);
                handleChangeValue(moment().format("HH:mm"))(e);
              } else if (isSelected && !props.multiple) {
                handleRemoveValue("", {})(e);
              }
            }
          }}
        />
        <div
          // role="listbox"
          id="listbox1"
          aria-hidden="true"
          aria-multiselectable={props.multiple ? "true" : "false"}
          className={`menu transition ${open ? "visible" : ""}`}
          onMouseOver={() => (isFocus.current = true)}
          onFocus={() => (isFocus.current = true)}
          onClick={() => (isFocus.current = true)}
          onMouseLeave={() => (isFocus.current = false)}
        >
          <Input
            ref={inputSearchRef}
            value={inputValue}
            fluid={true}
            style={{
              width: "calc(100% - 2rem)",
              margin: "0.35rem 1rem 0.25rem",
              display: props.searchBox ? "" : "none",
            }}
            onChange={handleChangeInput}
          />
          <div>
            {!options.length && props.search ? (
              <div className="message" style={{ padding: ".78571429rem 1.14285714rem" }}>
                No results found.
              </div>
            ) : (
              filterOptions.map((item) => {
                const checked = getChecked(item);

                return (
                  <div
                    key={"time-" + item.key}
                    style={{ pointerEvents: "all" }}
                    // role="option"
                    aria-selected={checked}
                    aria-hidden="true"
                    className={`${checked ? "active" : ""}${
                      checked && !props.checked ? ` selected` : ""
                    } item`}
                    onClick={handleSelected(item.value, {
                      item,
                      checked: !checked,
                    })}
                  >
                    <span
                      className="text"
                      style={{
                        paddingLeft: item.child ? "17.5px" : "",
                      }}
                    >
                      {!props.checked || (item.parent && !props.multiple) ? (
                        item.content ?? item.text
                      ) : (
                        <Checkbox
                          style={{ marginRight: "0.5rem" }}
                          checked={checked}
                          label={item.content ?? item.text}
                          onClick={(e) => e.stopPropagation()}
                          onChange={handleSelected(item.value, {
                            item,
                            checked: !checked,
                          })}
                        />
                      )}
                    </span>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

/* ------------------------------------------------------ */

/*                       LabelList;                       */

/* ------------------------------------------------------ */
const LabelList = (props: any) => {
  const handleClick = (data: any) => (e: any) => {
    props.onClick?.(e, data);
  };

  return (
    <>
      {props.multiple && Array.isArray(props.value) && (
        <span>
          {props.value.map((v: any) => {
            let data: any = props.optionsWithoutParent?.find((item: any) => item.value === v);
            // const

            return (
              data && (
                <a key={data.key} className="ui label" aria-hidden="true" onClick={props.onPrevent}>
                  {data.text}
                  <i
                    aria-hidden="true"
                    className="delete icon"
                    onMouseOver={props.onMouseOver}
                    onFocus={props.onFocus}
                    onMouseLeave={props.onMouseLeave}
                    onClick={handleClick(data)}
                  />
                </a>
              )
            );
          })}
        </span>
      )}
    </>
  );
};

DropdownOptions.displayName = "DropdownOptions";
export default React.memo(DropdownOptions);
