import React, {
  useState,
  useRef,
  MutableRefObject,
  CSSProperties,
  KeyboardEvent,
} from "react";
import { Input, TextArea, Form } from "semantic-ui-react";
import { useIntl } from "react-intl";

type EditorColumnProps = {
  id?: any;
  value: any;
  align?: CSSProperties["textAlign"];
  type?: "textarea" | "input";
  valueOnFocus?: boolean;
  selectOnFocus?: boolean;
  backgroundColor?: CSSProperties["background"];
  padding?: CSSProperties["padding"];
  disabled?: boolean;
  inputMin?: any;
  inputType?: string;
  inputNumber?: boolean;
  onDataChanged?: (value: string) => any;
};

const ColumnStyle = {
  background: "rgb(255, 255, 204)",
  marginTop: -7,
  marginLeft: -4,
  width: "calc(100% + 10px)",
  height: "calc(100% + 14px)",
  padding: "7px 7px",
} as CSSProperties;

const EditorColumn: React.FunctionComponent<EditorColumnProps> =
  React.memo<EditorColumnProps>((props) => {
    const [isEdit, setIsEdit] = useState<boolean>(false);

    const inputRef = useRef() as MutableRefObject<any>;

    const handleClick = () => {
      if (props?.disabled) {
        setIsEdit(false);
      } else {
        setIsEdit(true);

        setTimeout(() => {
          if (props.valueOnFocus) {
            const value = (inputRef.current.inputRef || inputRef.current.ref)
              .current.value;
            (inputRef.current.inputRef || inputRef.current.ref).current.value =
              value || props.value;
          }

          const elm = (inputRef.current.inputRef || inputRef.current.ref)
            ?.current;

          elm?.focus?.();

          if (props.selectOnFocus) {
            elm?.select?.();
          }
        }, 100);
      }
    };

    const handleBlur = () => {
      props.onDataChanged?.(
        (inputRef.current.inputRef || inputRef.current.ref)?.current?.value ||
          ""
      );

      setIsEdit(false);
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        handleBlur();
      }

      if (props.inputNumber) {
        if (!/[0-9]/.test(e.key) && e.key !== "Backspace" && e.key !== ".") {
          e.preventDefault();
        }
      }
    };

    return (
      <div
        id={props.id ? `Div-${props.id}` : `EditorColumn-Div`}
        style={{ ...ColumnStyle, background: props.backgroundColor }}
        onClick={handleClick}
      >
        {isEdit ? (
          <Form>
            {props.type === "textarea" ? (
              <TextArea
                id={props.id ? `TextArea-${props.id}` : `EditorColumn-TextArea`}
                ref={inputRef}
                style={{ width: "100%" }}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
                rows={3}
                disabled={props.disabled}
              />
            ) : (
              <Input
              id={props.id ? `Input-${props.id}` : `EditorColumn-Input`}
                ref={(ref: any) =>
                  ref &&
                  ((inputRef.current = ref),
                  !props.align &&
                    (ref.inputRef.current.style.textAlign = "right"))
                }
                style={{ width: "100%" }}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
                disabled={props.disabled}
                min={props.inputMin}
                type={props.inputType}
              />
            )}
          </Form>
        ) : (
          <div
            id={props.id ? `Div-Value-${props.id}` : `EditorColumn-Div-Value`}
            style={{
              textAlign: props.align ? props.align : "right",
              padding: props.padding,
            }}
          >
            {props.value}
          </div>
        )}
      </div>
    );
  });

EditorColumn.defaultProps = {
  type: "input",
};

export default EditorColumn;
